<template>
    <div v-if="myCreateShow" id="my_create" @click="myCreateShow = false">
        <div>
            <img class="colse" @click.stop="myCreateShow = false" src="../assets/image/close1.png" alt="">
            <div class="my_create1" @click.stop="myCreateShow = true">
                <div class="my_create2">
                    <el-select v-if="!correctShow" v-model="value" filterable placeholder="全部" @change="change">
                        <el-option v-for="item in options" :key="item.value" :value="item.label">
                        </el-option>
                    </el-select>
                    <div class="my_create3">历史记录</div>
                    <div class="my_create10" @click="clearCreate">
                        <div>一键清空</div>
                        <img src="../assets/image/clear.png" alt="">
                        <!-- <div v-if="!allDelshow" @click="openAlldel">批量删除</div>
                    <div v-else class="my_create11">
                        <div @click="allDelshow = false">取消</div>
                        <div style="color: #409EFF;" @click="choiceAll">{{ choiceallid ? '取消全选' : '全选' }}</div>
                        <div style="color: #FF2020;" @click="openAlldels">删除</div>
                    </div> -->
                    </div>
                </div>
                <div class="my_create4" ref="scrollContainer" @scroll="handleScroll">
                    <Nodata v-if="list.length == 0" :notext="notext"></Nodata>
                    <div v-else class="my_create5">
                        <div class="my_create6" v-for="(item, i) in list" :key="i" @click="choose(i, item.id)">
                            <div class="my_create7">
                                {{ item.type ? item.type : '作文批改' }}
                                <img v-if="allDelshow" :src="item.del ? icon[3] : icon[2]" alt="">
                            </div>
                            <div class="my_create8">{{ item.title }}</div>
                            <div class="my_create9" v-if="item.correctTime">
                                {{ item.correctTime }}
                                <img @click.stop="openDel(item.id)" src="../assets/image/card_del.png" alt="">
                            </div>
                            <div class="my_create9" v-else>
                                {{ `${item.year} / ${item.month} / ${item.day}` }}
                                <img @click.stop="openDel(item.id)" src="../assets/image/card_del.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 详情弹框 -->
        <div class="detail_popup" v-if="detailPopup" @click.stop="detailPopup = false">
            <div class="detail_popup1" @click.stop="detailPopup = true">
                <div class="detail_popup2">
                    {{ popupDetail.type }}
                    <img @click.stop="detailPopup = false" src="../assets/image/close.png" alt="">
                </div>
                <div class="detail_popup3" v-html="answer"></div>
                <div class="detail_popup4">
                    <div class="detail_popup5" @click.stop="copy">复制</div>
                    <div class="detail_popup5" @click.stop="detailPopup = false">关闭</div>
                </div>
            </div>
        </div>

        <!-- 批改作文详情 -->
        <div class="detail_popup" v-if="writedetailPopup" @click.stop="writedetailPopup = false">
            <div class="detail_write" @click.stop="writedetailPopup = true">
                <div class="detail_write1" @click.stop="writedetailPopup = true">
                    <div :class="writeTitleI == i ? 'detail_write2' : ''" v-for="(item, i) in writeTitle" :key="i"
                        @click="writeChoose(i)">
                        {{ item }}
                        <div v-if="writeTitleI == i" class="detail_write3"></div>
                    </div>
                </div>
                <div class="detail_write4" v-if="writeTitleI == 0">
                    <div class="detail_write5" v-for="(item, i) in evaluate" :key="i">
                        <div class="detail_write6">
                            {{ item.title }}
                        </div>
                        <div class="detail_write7">
                            {{ item.content }}
                        </div>
                    </div>
                </div>
                <div class="detail_write4" v-if="writeTitleI == 1">
                    <div v-if="!anwserShow">
                        <Nodata v-if="!polishShow" :notext="['暂未润色']"></Nodata>
                        <div v-else class="detail_write7 detail_write8" v-html="polish"></div>
                    </div>
                    <div class="detail_write7 detail_write8" ref="chatContainer" v-else>
                        <Typewriter ref="typewriter" size="1.4" :content="anwser" @anwserOver="anwserOver"></Typewriter>
                    </div>
                    <createBottom v-if="polishShow" @bottomBTn="bottomBTn"></createBottom>
                </div>
                <div class="detail_write4" v-if="writeTitleI == 2">
                    <div class="detail_write7 detail_write8" v-html="original"></div>
                    <createBottom v-if="!polishShow" :composition="false" @polishing="polishing"></createBottom>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Nodata from './nodata.vue';
import data from '@/assets/data';
import api from "@/api/api";
import createBottom from './create_bottom.vue';
import Typewriter from './typewriter.vue';
import { saveAs } from 'file-saver';
import { marked } from 'marked';
export default {
    components: {
        Nodata, createBottom, Typewriter
    },
    created() {

    },
    data() {
        return {
            notext: ['暂无数据'],
            icon: data.data.historyBtn,
            polish: '', // 润色后
            original: '', // 原文
            polishShow: false, // 是否润色
            anwser: '', // 一键润色回答
            anwserShow: false,//一键润色回答显示
            polishfinish: true, //润色完成
            writeTitle: ['综合评价', '改写润色', '原文'], //批改作文详情
            writeTitleI: 0,//批改作文详情下标
            myCreateShow: false, //弹框显示隐藏
            writeId: 0, //作文id
            detailPopup: false, //详情弹框显示隐藏
            writedetailPopup: false,//批改作文详情弹框显示隐藏
            popupDetail: {}, //详情内容
            answer: '',
            options: [], // 所有type
            typename: '', //选中type
            value: '',
            list: [], //历史记录列表
            pageNum: 1, // 页码
            stopload: false,//触底是否请求
            allDelshow: false, //批量删除
            choiceallid: false, //全选id
            correctShow: false,
            evaluate: data.data.evaluate, //综合评价
            url: process.env.VUE_APP_BASE_API + "assistant/app/question/stream/zwrs", //一键润色
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': localStorage.getItem('token_key')
            }, //请求头

        }
    },
    methods: {
        // 控制弹框显示隐藏
        show(item) {
            this.myCreateShow = true
            this.correctShow = false
            if (item) {
                this.getWriteList()
                this.correctShow = true
            } else {
                this.getCreateType()
                this.getCreateHistory('')
            }
        },
        // 获取全部lable
        getCreateType() {
            this.options = []
            api.getCreateType().then((res) => {
                // console.log(res)
                if (res.code == 200) {
                    res.data.map((item) => {
                        let obj = {
                            label: ''
                        }
                        obj.label = item
                        this.options.push(obj)
                    })
                }
            });
        },
        // 获取历史记录
        getCreateHistory(type) {
            api.getCreateHistory({
                type: type,
                pageNum: this.pageNum,
                pageSize: 21
            }).then((res) => {
                if (res.code == 200) {
                    this.list.push(...res.data)
                    if (res.data.length == 21) {
                        this.pageNum++
                    } else {
                        this.stopload = true
                    }
                }
            });
        },
        // 获取批改作业列表
        getWriteList() {
            api.getWriteList({
                pageNum: this.pageNum,
                pageSize: 21
            }).then((res) => {
                // console.log(res)
                if (res.code == 200) {
                    this.list.push(...res.data)
                    if (res.data.length == 21) {
                        this.pageNum++
                    } else {
                        this.stopload = true
                    }
                }
            })
        },
        // 切换查询记录列表
        change(i) {
            this.list = []
            this.pageNum = 1
            this.typename = i
            this.stopload = false
            this.getCreateHistory(i)

        },
        handleScroll() {
            const scrollContainer = this.$refs.scrollContainer;
            const { scrollTop, clientHeight, scrollHeight } = scrollContainer;
            if (scrollTop + clientHeight + 1 >= scrollHeight) {
                if (this.stopload) {
                    this.$message({
                        message: '暂无更多数据',
                        type: 'warning'
                    });
                    return
                }
                if (this.correctShow) {
                    this.getWriteList()
                } else {
                    this.getCreateHistory(this.typename)
                }

            }
        },
        // 点击选择内容
        choose(i, id) {
            if (this.allDelshow) {
                this.choiceDel(id)
                if (this.list.every(item => item.del === true)) {
                    this.choiceallid = true
                } else {
                    this.choiceallid = false
                }
                return
            }
            this.CreateDetail(id)
        },
        // // 批量删除
        // openAlldel() {
        //     this.allDelshow = true
        //     this.list = this.list.map(item => ({ ...item, del: false }))
        // },
        // 选择删除记录
        choiceDel(id) {
            this.list = this.list.map(item => {
                if (id == item.id) {
                    item.del = !item.del
                }
                return item
            })
        },
        // // 全选
        // choiceAll() {
        //     this.choiceallid = !this.choiceallid
        //     this.list = this.list.map(item => {
        //         item.del = this.choiceallid
        //         return item
        //     })
        // },
        // // 批量删除
        // openAlldels() {
        //     if (!this.list.some(item => item.del)) {
        //         this.$message({
        //             type: 'warning',
        //             message: '请选择删除内容！'
        //         })
        //         return
        //     }
        //     this.$confirm('此操作将永久删除该文件, 是否继续?', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //         type: 'warning'
        //     }).then(() => {
        //         let ids = this.list.filter(item => item.del).map(item => item.id)
        //         // console.log('删除--', ids)
        //         this.del(ids)
        //     }).catch(() => {
        //         this.$message({
        //             type: 'info',
        //             message: '已取消删除'
        //         });
        //     });
        // },
        // 作文批改打开删除弹框
        openDel(id) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.deluCreate(id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 单个删除
        deluCreate(id) {
            if (this.correctShow) {
                api.delWrite(id).then((res) => {
                    if (res.code == 200) {
                        this.list = this.list.filter((item, i) => {
                            if (id !== item.id) {
                                return item;
                            }
                        });
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                    }
                })
            } else {
                api.deluCreate(id).then((res) => {
                    if (res.code == 200) {
                        this.list = this.list.filter((item, i) => {
                            if (id !== item.id) {
                                return item;
                            }
                        });
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                    }
                })
            }

        },
        // 复制
        copy() {
            this.$copyText(this.popupDetail.answer).then(() => {
                this.$message.success('文本已复制');
            }).catch(() => {
                this.$message.warning('复制失败');
            })
        },
        // 获取详情
        CreateDetail(id) {
            if (this.correctShow) {
                api.writeDetail({
                    id: id
                }).then((res) => {
                    if (res.code == 200) {
                        // console.log(res.data)
                        this.polishShow = false
                        this.writedetailPopup = true
                        this.polish = ''
                        this.evaluate[0].content = JSON.parse(res.data.assess).summary
                        this.evaluate[1].content = JSON.parse(res.data.assess).advantage
                        this.evaluate[2].content = JSON.parse(res.data.assess).recommend
                        this.writeId = res.data.id
                        if (res.data.polish) {
                            this.polishShow = true
                            this.polish = res.data.polish.replace(/\n/g, "<br/>")
                        }
                        this.original = res.data.original.replace(/\n/g, "<br/>")
                    }
                });
            } else {
                api.CreateDetail({
                    id: id
                }).then((res) => {
                    if (res.code == 200) {
                        this.detailPopup = true
                        // this.answer = res.data.answer.replace(/\n/g, "<br/>")
                        this.answer = marked(res.data.answer) 
                        this.popupDetail = res.data
                    }
                });
            }

        },

        //作文批改切换
        writeChoose(i) {
            if (!this.polishfinish) {
                this.$message.warning('请等待润色完成！');
                return
            }
            this.writeTitleI = i
        },
        // 一键润色
        async polishing() {
            this.writeTitleI = 1
            this.anwserShow = true
            let response = await fetch(this.url, {
                method: 'POST',
                body: `id=${this.writeId}`,
                headers: this.headers
            })
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // 获取可读流读取器对象 循环
            const reader = response.body.getReader();
            this.polishShow = true
            this.polishfinish = false
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                const decoder = new TextDecoder();
                const txt = decoder.decode(value);
                const t = this.getStr(txt)
                if (t && t != '') {
                    this.anwser = this.anwser + t;
                    //   console.log(this.anwser)
                }
                if (this.myCreateShow) {
                    this.scrollToBottom()
                }
            }
            this.$refs.typewriter.overtext()
        },
        // 返回值拼接
        getStr(str) {
            // console.log('str',str)
            if (this.tmp) {
                str = this.tmp + str;
            }
            let arr = str.split("\n\n");

            let res = "";
            for (let s of arr) {
                if (!s || s == "") {
                    continue;
                }
                try {
                    let obj = JSON.parse(s);
                    const code = obj.code;
                    if ("401" == code) {
                        return "";
                    } else if ("700" == code) {
                        break;
                    } else if ("200" != code) {
                        return obj.msg;
                    } else {
                        const data = obj.data.replace(/\n/g, "<br/>");
                        res += data;
                    }
                    this.tmp = null;
                } catch (err) {
                    console.log(err);
                    if (this.tmp) {
                        this.tmp = null
                    } else {
                        this.tmp = str
                    }
                }
            }
            return res;
        },
        anwserOver() {
            this.polishfinish = true
        },
        bottomBTn(i) {
            if (!this.polishfinish) {
                this.$message.warning('请等待润色完成！');
                return
            }
            let downloadTxt = ''
            if (this.answer) {
                downloadTxt = this.answer.replace(/<br\s*\/?>/g, '\n');
            } else {
                downloadTxt = this.polish.replace(/<br\s*\/?>/g, '\n');
            }
            if (i == 0) {
                let strData = new Blob([downloadTxt], { type: 'text/plain;charset=utf-8' });
                saveAs(strData, `作文批改.txt`);
            } else if (i == 1) {
                this.$copyText(downloadTxt).then(() => {
                    this.$message.success('文本已复制');
                }).catch(() => {
                    this.$message.warning('复制失败');
                })
            }
        },
        scrollToBottom() {
            // 使用Vue的$nextTick确保DOM更新完成后再进行滚动操作
            this.$nextTick(() => {
                const chatContainer = this.$refs.chatContainer;
                chatContainer.scrollTop = chatContainer.scrollHeight; // 滚动到底部
            });
        },

        // 一键清空
        clearCreate() {
            this.$confirm(`此操作将永久删除${this.typename == '' ? '全部' : this.typename}记录, 是否继续?`, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                api.clearCreate({
                    type: this.typename
                }).then((res) => {
                    console.log(res)
                    if (res.code == 200) {
                        this.$message.success(`${this.typename}记录已清空！`);
                        this.list = []
                        this.pageNum = 1
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        }
    }
}
</script>

<style>
#my_create {
    position: fixed;
    top: 0;
    width: 128rem;
    height: 100%;
    background-color: #00000038;
    z-index: 1000;
}

.my_create2 .el-input {
    width: 12rem !important;
    font-size: 1rem !important;
}

.el-select-dropdown__list {
    max-height: 30rem !important;
}

.my_create1 {
    position: absolute;
    right: 0;
    width: 70rem;
    height: 100vh;
    background-color: #F3F3F3;
    border-radius: 0.5rem 0 0 0.5rem;
}

.my_create2 {
    width: 70rem;
    height: 8vh;
    background-color: #FFFFFF;
    border-top-left-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    position: relative;
}

.colse {
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 71rem;
    top: 50vh;
    transform: translateY(-50%);
}

.my_create3 {
    color: #111111;
    font-size: 1.2rem;
    font-weight: 600;
}

.my_create4 {
    width: 70rem;
    height: 92vh;
    overflow: auto;
    -ms-overflow-style: none;
    /* IE和Edge浏览器下隐藏滚动条 */
    scrollbar-width: none;
    /* Firefox下隐藏滚动条 */
}

.my_create5 {
    width: 70rem;
    padding: 2rem;
    padding-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}

.my_create6 {
    width: 21rem;
    background: #FFFFFF;
    margin-bottom: 1.5rem;
    border-radius: 0.5rem;
    overflow: hidden;
}

.my_create6:nth-child(3n-1) {
    margin: 0 1.5rem;
    margin-bottom: 1.5rem;
}

.my_create7 {
    width: 21rem;
    height: 2.5rem;
    font-size: 1rem;
    font-weight: 700;
    color: #333333;
    padding: 0 1.2rem;
    background: #D1DDFC;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.my_create7 img {
    width: 1.15rem;
    height: 1.1rem;
}

.my_create8 {
    width: 21rem;
    padding: 0.8rem 1rem;
    font-size: 1rem;
    color: #333333;
    height: 4.7rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.8rem
}

.my_create9 {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    font-size: 0.8rem;
    color: #999999;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.my_create9 img {
    width: 1.2rem;
}

.my_create10 {
    width: 12rem;
    font-size: 1.1rem;
    color: #666666;
    display: flex;
    justify-content: end;
    align-items: center;
    cursor: default;
}

.my_create10 img {
    width: 1.2rem;
    height: 1.2rem;
    margin-left: 0.5rem;
}

/* .my_create11 {
    width: 12rem;
    display: flex;
    justify-content: space-between;
} */

.popup {
    /* overflow: auto;
  padding: 3rem;
  padding-top: 0; */
}

.popup_title {
    font-size: 2.2rem;
    margin-bottom: 2rem;
    text-align: start;
    padding-left: 3rem;
}

.popup_text {
    height: 60vh;
    overflow: auto;
    font-size: 1.2rem;
    color: #000;
    text-align: start;
    overflow: auto;
    padding: 3rem;
}

.popup_btn {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: end;
    width: 100%;
    padding-bottom: 1rem;
    background: #fff;
}

.popup_btn .popup_btn1 {
    /* width: 5rem; */
    background: #409eff;
    margin-right: 2rem;
    color: #fff;
    font-size: 1.2rem;
    padding: 0.5rem 0.8rem;
    border-radius: 0.5rem;
    cursor: pointer;
}


.detail_popup {
    position: fixed;
    top: 0;
    width: 128rem;
    height: 100%;
    background-color: #00000038;
    z-index: 1001;
}

.detail_popup1 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80rem;
    height: 80vh;
    background-color: #FFFFFF;
    border-radius: 0.5rem;
}

.detail_popup2 {
    display: flex;
    width: 80rem;
    height: 5vh;
    padding: 0 2rem;
    align-items: center;
    justify-content: space-between;
    font-size: 1.6rem;
    font-weight: 700;
    color: #606266;
}

.detail_popup2 img {
    width: 2rem;
}

.detail_popup3 {
    height: 70vh;
    padding: 0 2rem;
    font-size: 1.2rem;
    color: #000;
    overflow: auto;
    -ms-overflow-style: none;
    /* IE和Edge浏览器下隐藏滚动条 */
    scrollbar-width: none;
    /* Firefox下隐藏滚动条 */
}

.detail_popup4 {
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: end;
}

.detail_popup5 {
    width: 5rem;
    height: 4vh;
    text-align: center;
    line-height: 4vh;
    font-size: 1.2rem;
    color: #FFFFFF;
    border-radius: 0.5rem;
    background-color: #409eff;
    margin-right: 1rem;
    cursor: default;
}

.detail_write {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
    width: 70%;
    height: 90vh;
    background-color: #FFFFFF;
    border-radius: 1rem 1rem 0 0;
    padding-bottom: 10vh;
}

.detail_write1 {
    width: 50%;
    margin: 0 auto;
    display: flex;
    font-size: 1.6rem;
    color: #888888;
    justify-content: space-between;
    height: 5vh;
    line-height: 5vh;
    cursor: default;
    /* background-color: orange; */
}

.detail_write2 {
    color: #333333;
    font-weight: 700;
    position: relative;
}

.detail_write3 {
    width: 2.6rem;
    height: 0.6rem;
    border-radius: 0.6rem;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
}

.detail_write4 {
    width: 100%;
    height: 76vh;
    /* background-color: orange; */
}

.detail_write7 {
    width: 100%;
    padding: 0 10%;
    font-size: 1.4rem;
    margin-bottom: 1.2rem;
}

.detail_write8 {
    height: 76vh;
    overflow: auto;
    -ms-overflow-style: none;
    /* IE和Edge浏览器下隐藏滚动条 */
    scrollbar-width: none;
    /* Firefox下隐藏滚动条 */
    /* background-color: #409EFF; */
}

.detail_write6 {
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 1rem;
    width: 100%;
    padding: 0 10%;
}
</style>