<template>
    <div class="result_content" ref="chatContainer">
        <div class="result_content1">
            <div class="result_content2">{{ lable.name }}</div>
            <div class="result_content3" v-for="(item, i) in requestDatas[0]" :key="i">
                <div class="result_content4">
                    {{ item.title }}：
                </div>
                <div class="result_content5">
                    {{ item.content }}
                </div>
            </div>
        </div>
        <div class="result_content6">
            <div v-html="anwser"></div>
            <!-- <Typewriter ref="typewriter" :content="anwser" @anwserOver="anwserOver"></Typewriter> -->
        </div>
    </div>
</template>

<script>
import { EventBus } from "@/utils/eventBus";
import { saveAs } from 'file-saver';
import Typewriter from './typewriter.vue';
import api from "@/api/api";
import { marked } from 'marked';
export default {
    props: {
        lable: {
            type: Object,
            default: {}
        },
        requestDatas: {
            typeof: Array,
            default: []
        }
    },
    components: {
        Typewriter
    },

    data() {
        return {
            anwser: "",
            anwserText:'',
            url: process.env.VUE_APP_BASE_API + "assistant/app/question/stream/wdlj", //上传地址
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token_key')
            }, //请求头
            formData: new FormData(), //FormData参数
            intervalId: null, // 定时器ID
            stopTalkid: 0,// 停止对话id
            nextAnwers: true, // 下一次对话
            stopask: false, //停止提问
            tmp: null,
            streamIndex:0, //流式下标
        }
    },
    beforeDestroy() {

    },
    methods: {
        // 下载
        downloadText() {
            if (!this.nextAnwers) {
                this.$message({
                    message: '请等待问题回答完毕，或者手动停止！',
                    type: 'warning'
                });
                return
            }
            let downloadTxt = this.anwser.replace(/<br\s*\/?>/g, '\n');
            downloadTxt = downloadTxt.replace(/<[^>]*>/g, '')
            let strData = new Blob([downloadTxt], { type: 'text/plain;charset=utf-8' });
            saveAs(strData, `${this.lable.name}.txt`);
        },
        // 复制
        copyText() {
            if (!this.nextAnwers) {
                this.$message({
                    message: '请等待问题回答完毕，或者手动停止！',
                    type: 'warning'
                });
                return
            }
            let downloadTxt = this.anwser.replace(/<br\s*\/?>/g, '\n');
            downloadTxt = downloadTxt.replace(/<[^>]*>/g, '')
            this.$copyText(downloadTxt).then(() => {
                this.$message.success('复制成功');
            }).catch(() => {
                this.$message.warning('复制失败');
            })
        },
        // 停止
        stopText() {
            this.stopTalk()
        },
        // 流式请求获取回答
        async getAnwser(item) {
            this.anwser = ''
            api.getStreamczId(this.lable.type, item).then((res) => {
                if (res.code == 200) {
                    this.stopTalkid = res.data
                    this.getStreamContent(res.data)
                    this.nextAnwers = true
                }
            })
        },
        getStreamContent(id) {
            api.getStreamContent(id, { index: this.streamIndex }).then((res) => {
                if (res.data.length !== 0) {
                    this.streamIndex = this.streamIndex + res.data.length
                    res.data.map((item) => {
                        if (item !== '[DONE]') {
                            this.anwserText += item
                            this.anwser = marked(this.anwserText)
                        }
                    })
                    if (res.data[res.data.length - 1] == '[DONE]') {
                        this.streamIndex = 0
                        this.anwserText = ''
                        this.nextAnwers = false
                        this.anwserOver()
                        return
                    }
                    this.getStreamContent(id)
                } else {
                    this.getStreamContent(id)
                }
            })
        },

        // 文档理解
        async wdlj(data) {
            this.anwser = ''
            this.anwserText = ''
            this.formData.append('file', data.file);
            this.formData.append('question', data.question);
            let response = await fetch(this.url, {
                method: 'POST',
                body: this.formData,
                headers: this.headers
            })
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            this.intervalId = setInterval(() => {
                this.scrollToBottom();
            }, 500)
            // 获取可读流读取器对象 循环
            const reader = response.body.getReader();
            EventBus.$emit('anwserStart');
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                const decoder = new TextDecoder();
                const txt = decoder.decode(value);
                const t = this.getStr(txt)
                if (t && t != '') {
                    this.anwserText += t
                    if (this.anwserText) {
                        this.anwser = marked(this.anwserText);
                    }
                }
            }
            this.anwserOver()

        },

        // 返回值拼接
        getStr(str) {
            // console.log('str',str)
            if (this.tmp) {
                str = this.tmp + str;
            }
            let arr = str.split("\n\n");

            let res = "";
            for (let s of arr) {
                if (!s || s == "") {
                    continue;
                }
                try {
                    let obj = JSON.parse(s);
                    const code = obj.code;
                    if ("401" == code) {
                        return "";
                    } else if ("700" == code) {
                        break;
                    } else if ("200" != code) {
                        return obj.msg;
                    } else {
                        const data = obj.data;
                        res += data;
                        this.stopTalkid = obj.id
                    }
                    this.tmp = null;
                } catch (err) {
                    console.log(err);
                    if (this.tmp) {
                        this.tmp = null
                    } else {
                        this.tmp = str
                    }
                }
            }
            return res;
        },
        anwserOver() {
            this.nextAnwers = true
            this.anwserText = ''
            EventBus.$emit('anwserOver');
            clearInterval(this.intervalId)
        },
        scrollToBottom() {
            // 使用Vue的$nextTick确保DOM更新完成后再进行滚动操作
            this.$nextTick(() => {
                const chatContainer = this.$refs.chatContainer;
                chatContainer.scrollTop = chatContainer.scrollHeight; // 滚动到底部
            });
        },
        //停止对话
        stopTalk() {
            api.stopquestion(this.stopTalkid).then((res) => {
                if (res.code == 200) {
                    clearInterval(this.intervalId)
                }
            })
        }
    }
}
</script>

<style>
.result_content {
    width: 82rem;
    height: 74vh;
    padding: 0 2rem;
    background-color: #ffffff7a;
    overflow: auto;
    -ms-overflow-style: none;
    /* IE和Edge浏览器下隐藏滚动条 */
    scrollbar-width: none;
    /* Firefox下隐藏滚动条 */
    /* background-color: #409EFF; */
}

.result_content1 {
    width: 100%;
    padding: 0.8rem 0;
    border-bottom: 1px solid #BBBBBB;
}

.result_content2 {
    color: #999999;
    font-size: 1.2rem;
}

.result_content3 {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
}

.result_content4 {
    width: 8.5rem;
    font-size: 1rem;
    color: #111111;
    text-align: right;
}

.result_content5 {
    width: 79rem;
    font-size: 1rem;
    color: #409EFF;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.result_content6 {
    padding: 1rem;
}
</style>