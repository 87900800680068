import http from '../utils/http'
// 
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = process.env.VUE_APP_BASE_API

export default {
    // 首页 写作，会员
    getHome(params) {
        return http.get(`${resquest}assistant/public/template/category/v2`, params)
    },
    // 办公列表
    getWork(params) {
        return http.get(`${resquest}assistant/public/module/template`, params)
    },
    // 获取提示
    getPrompt(params) {
        return http.get(`${resquest}system/public/app/prompt/${params}`)
    },
    //顾问列表
    getAdviser(params) {
        return http.get(`${resquest}assistant/public/adviser/list`, params)
    },
    //获取vip价格
    getViplist(params) {
        return http.get(`${resquest}assistant/public/member/price`, params)
    },

    // 登录
    // 获取验证码
    GetCode(params) {
        return http.post(`${resquest}auth/app/sms/v2`, params)
    },
    // 手机登录
    loginPhone(params) {
        return http.post(`${resquest}auth/app/login/sms/pc`, params)
    },
    // 退出登录
    logout(params) {
        return http.delete(`${resquest}auth/app/logout`, params)
    },
    // 获取个人信息
    getUserinfo(params) {
        return http.get(`${resquest}assistant/app/atuser/userinfo`, params)
    },
    //免费次数
    degree(params) {
        return http.get(`${resquest}assistant/app/degree`, params)
    },
    // 微信扫码登录
    loginWx(params) {
        return http.post(`${resquest}auth/app/login/wx/pc`, params)
    },
    // 获取我的创作列表
    getCreateHistory(params) {
        return http.get(`${resquest}assistant/app/question/log/`, params)
    },
    // 获取我的创作列表type
    getCreateType(params) {
        return http.get(`${resquest}assistant/app/question/log/type`, params)
    },
    //删除我的创作
    deluCreate(params) {
        return http.delete(`${resquest}assistant/app/question/log/` + params)
    },
    //创作记录详情
    CreateDetail(params) {
        return http.get(`${resquest}assistant/app/question/log/get`, params)
    },
    // 对话历史记录
    sessionList(params) {
        return http.get(`${resquest}assistant/app/session/list`, params)
    },
    // 删除聊天记录
    deleteSession(params) {
        return http.post(`${resquest}assistant/app/session/delete`, params)
    },
    // 创建会话
    createSession(params) {
        return http.get(`${resquest}assistant/app/session/create`, params)
    },
    // 流式请求获取id
    getStreamId(params){
        return http.post(`${resquest}assistant/app/session/question/async`, params)
    },
    // 流式请求通过id获取内容
    getStreamContent(id,params) {
        return http.get(`${resquest}assistant/app/question/async/${id}`,params)
    },
    // 智能创作流式请求获取id
    getStreamczId(type,params){
        return http.post(`${resquest}assistant/app/question/async/${type}`, params)
    },
    // 停止流式请求
    stopquestion(params) {
        return http.delete(`${resquest}assistant/app/question/cancel/` + params)
    },
    // 查询对话详情
    contentSession(params) {
        return http.get(`${resquest}assistant/app/session/content`, params)
    },
    // 会员支付
    payVip(params) {
        return http.post(`${resquest}assistant/app/recharge/pc`, params)
    },
    // 查询转态
    vipStatus(params) {
        return http.get(`${resquest}assistant/app/pay/status/` + params)
    },
    // 作文批改记录
    getWriteList(params){
        return http.get(`${resquest}assistant/app/assess/list`, params)
    },
    // 删除作文批改记录
    delWrite(params) {
        return http.delete(`${resquest}assistant/app/assess/` + params)
    },
    //作文批改详情
    writeDetail(params) {
        return http.get(`${resquest}assistant/app/assess/get`, params)
    },
     //作文批改润色
     writePolish(params) {
        return http.get(`${resquest}assistant/app/question/stream/zwrs`, params)
    },
    // 清空创作记录
    clearCreate(params) {
        return http.post(`${resquest}assistant/app/question/log/clear`, params)
    },
}