<template>
    <div class="createContent">
        <div class="createContent1">
            {{ lable.name }}
            <img v-if="lable.title == '会员专区'" src="../assets/image/composition.png" alt="">
        </div>
        <div v-if="!found" class="createContent6">
            <img src="../assets/image/expect.png" alt="">
        </div>

        <div v-else class="createContent7">
            <Xhs v-if="lable.type == 'xhswa' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Xhs>
            <Ggwa v-if="lable.type == 'ggwa' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Ggwa>
            <Xxs v-if="lable.type == 'xxs' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Xxs>
            <Xwz v-if="lable.type == 'xwz' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Xwz>
            <Xxwg v-if="lable.type == 'xxwg' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Xxwg>
            <Ycgx v-if="lable.type == 'ycgx' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Ycgx>
            <Xzw v-if="lable.type == 'xzw' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Xzw>
            <Gzh v-if="lable.type == 'gzh' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Gzh>
            <Zhwt v-if="lable.type == 'zhwt' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Zhwt>
            <Wzrs v-if="lable.type == 'wzrs' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Wzrs>
            <Spjb v-if="lable.type == 'spjb' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Spjb>
            <Dypl v-if="lable.type == 'dypl' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Dypl>
            <Gjctq v-if="lable.type == 'gjctq' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Gjctq>
            <Lxbg v-if="lable.type == 'lxbg' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Lxbg>
            <Yjg v-if="lable.type == 'yjg' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Yjg>
            <Znfy v-if="lable.type == 'znfy' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Znfy>
            <Wbzy v-if="lable.type == 'wbzy' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Wbzy>

            <!-- vip -->
            <Wgxx v-if="lable.type == 'wgxx' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Wgxx>
            <Xmtxz v-if="lable.type == 'xmtxz' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Xmtxz>
            <Jasj v-if="lable.type == 'jasj' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Jasj>
            <Zngx v-if="lable.type == 'zngx' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Zngx>
            <Ggc v-if="lable.type == 'ggc' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Ggc>
            <Dhkbg v-if="lable.type == 'dhkbg' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Dhkbg>
            <Spnrlg v-if="lable.type == 'spnrlg' && pageShow" ref="creation" :pageContent="content"
                :disabled="disabled">
            </Spnrlg>
            <Dhjb v-if="lable.type == 'dhjb' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Dhjb>
            <Spbt v-if="lable.type == 'spbt' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Spbt>
            <Lwjc v-if="lable.type == 'lwjc' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Lwjc>
            <Wdlj v-if="lable.type == 'wdlj'" ref="creation" :disabled="disabled"></Wdlj>
            <Zwpg v-if="lable.type == 'zwpg'" ref="creation"></Zwpg>

            <!-- office -->
            <Gwxz v-if="lable.type == 'gwxz' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Gwxz>
            <Gzjh v-if="lable.type == 'gzjh' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Gzjh>
            <Hychfa v-if="lable.type == 'hychfa' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Hychfa>
            <Gzhb v-if="lable.type == 'gzhb' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Gzhb>
            <Pptdg v-if="lable.type == 'pptdg' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Pptdg>
            <Htmb v-if="lable.type == 'htmb' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Htmb>
            <Zbs v-if="lable.type == 'zbs' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Zbs>
            <Khzj v-if="lable.type == 'khzj' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Khzj>
            <Zgbg v-if="lable.type == 'zgbg' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Zgbg>
            <Zczj v-if="lable.type == 'zczj' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Zczj>
            <Hyjy v-if="lable.type == 'hyjy' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Hyjy>
            <Yjya v-if="lable.type == 'yjya' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Yjya>
            <Xdth v-if="lable.type == 'xdth' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Xdth>
            <Gzzj v-if="lable.type == 'gzzj' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Gzzj>
            <Fxbg v-if="lable.type == 'fxbg' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Fxbg>
            <Hdfa v-if="lable.type == 'hdfa' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Hdfa>
            <Pxzj v-if="lable.type == 'pxzj' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Pxzj>
            <Jhxd v-if="lable.type == 'jhxd' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Jhxd>

            <!-- education教育 -->
            <Zw v-if="lable.type == 'zw' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Zw>
            <Xkct v-if="lable.type == 'xkct' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Xkct>
            <Hdch v-if="lable.type == 'hdch' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Hdch>
            <!-- writing媒体写作 -->
            <Ds v-if="lable.type == 'ds' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Ds>
            <Yzhp v-if="lable.type == 'yzhp' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Yzhp>
            <Wz v-if="lable.type == 'wz' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Wz>
            <Yjzx v-if="lable.type == 'yjzx' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Yjzx>
            <Swot v-if="lable.type == 'swot' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Swot>
            <!-- study学习 -->
            <Aibc v-if="lable.type == 'aibc' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Aibc>
            <Zbgs v-if="lable.type == 'zbgs' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Zbgs>
            <Lw v-if="lable.type == 'lw' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Lw>
            <Sgqs v-if="lable.type == 'sgqs' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Sgqs>
            <Xxjh v-if="lable.type == 'xxjh' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Xxjh>
            <!-- recreation娱乐 -->
            <Tkx v-if="lable.type == 'tkx' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Tkx>
            <Jrzf v-if="lable.type == 'jrzf' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Jrzf>
            <Hny v-if="lable.type == 'hny' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Hny>
            <Kzs v-if="lable.type == 'kzs' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Kzs>
            <Sgzs v-if="lable.type == 'sgzs' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Sgzs>
            <Lxgl v-if="lable.type == 'lxgl' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Lxgl>
            <Gxqm v-if="lable.type == 'gxqm' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Gxqm>
            <Xqrz v-if="lable.type == 'xqrz' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Xqrz>
            <Rap v-if="lable.type == 'rap' && pageShow" ref="creation" :pageContent="content" :disabled="disabled">
            </Rap>
        </div>


        <div v-if="found" class="createContent3" @click="generate">
            <div v-if="freenum >= 0" class="createContent4">{{ `免费次数${freenum}` }}</div>
            <div class="createContent5">{{ btnText }}</div>
        </div>
    </div>
</template>

<script>
import { EventBus } from "@/utils/eventBus";
import { pagelist } from '@/utils/pagelist.js'
import api from "@/api/api";
import Xhs from './home/xhs.vue';
import Ggwa from "./home/ggwa.vue";
import Xxs from "./home/xxs.vue";
import Wdlj from "./vip/wdlj.vue";
import Zwpg from "./vip/zwpg.vue";
import Xwz from "./home/xwz.vue";
import Xxwg from "./home/xxwg.vue";
import Ycgx from "./home/ycgx.vue";
import Xzw from "./home/xzw.vue";
import Gzh from "./home/gzh.vue";
import Zhwt from "./home/zhwt.vue";
import Wzrs from "./home/wzrs.vue";
import Spjb from "./home/spjb.vue";
import Dypl from "./home/dypl.vue";
import Gjctq from "./home/gjctq.vue";
import Lxbg from "./home/lxbg.vue";
import Yjg from "./home/yjg.vue";
import Znfy from "./home/znfy.vue";
import Wbzy from "./home/wbzy.vue";
import Wgxx from "./vip/wgxx.vue";
import Xmtxz from "./vip/xmtxz.vue";
import Jasj from "./vip/jasj.vue";
import Zngx from "./vip/zngx.vue";
import Ggc from "./vip/ggc.vue";
import Dhkbg from "./vip/dhkbg.vue";
import Spnrlg from "./vip/spnrlg.vue";
import Dhjb from "./vip/dhjb.vue";
import Spbt from "./vip/spbt.vue";
import Lwjc from "./vip/lwjc.vue";
import Gwxz from "./office/gwxz.vue";
import Gzjh from "./office/gzjh.vue";
import Hychfa from "./office/hychfa.vue";
import Gzhb from "./office/gzhb.vue";
import Pptdg from "./office/pptdg.vue";
import Htmb from "./office/htmb.vue";
import Zbs from "./office/zbs.vue";
import Khzj from "./office/khzj.vue";
import Zgbg from "./office/zgbg.vue";
import Zczj from "./office/zczj.vue";
import Hyjy from "./office/hyjy.vue";
import Yjya from "./office/yjya.vue";
import Xdth from "./office/xdth.vue";
import Gzzj from "./office/gzzj.vue";
import Fxbg from "./office/fxbg.vue";
import Hdfa from "./office/hdfa.vue";
import Pxzj from "./office/pxzj.vue";
import Jhxd from "./office/jhxd.vue";
import Zw from "./education/zw.vue";
import Xkct from "./education/xkct.vue";
import Hdch from "./education/hdch.vue";
import Ds from "./writing/ds.vue";
import Yzhp from "./writing/yzhp.vue";
import Wz from "./writing/wz.vue";
import Yjzx from "./writing/yjzx.vue";
import Swot from "./writing/swot.vue";
import Aibc from "./study/aibc.vue";
import Zbgs from "./study/zbgs.vue";
import Lw from "./study/lw.vue";
import Sgqs from "./study/sgqs.vue";
import Xxjh from "./study/xxjh.vue";
import Tkx from "./recreation/tkx.vue";
import Jrzf from "./recreation/jrzf.vue";
import Hny from "./recreation/hny.vue";
import Kzs from "./recreation/kzs.vue";
import Sgzs from "./recreation/sgzs.vue";
import Lxgl from "./recreation/lxgl.vue";
import Gxqm from "./recreation/gxqm.vue";
import Xqrz from "./recreation/xqrz.vue";
import Rap from "./recreation/rap.vue";


export default {
    components: {
        Xhs, Ggwa, Xxs, Xwz, Xxwg, Ycgx, Xzw, Gzh, Zhwt, Wzrs, Wdlj, Zwpg, Spjb, Dypl,
        Gjctq, Lxbg, Yjg, Znfy, Wbzy, Wgxx, Xmtxz, Jasj, Zngx, Ggc, Dhkbg, Spnrlg, Dhjb, 
        Spbt, Lwjc,Gwxz, Gzjh,Hychfa,Gzhb,Pptdg,Htmb,Zbs,Khzj,Zgbg,Zczj,Hyjy,Yjya,Xdth,
        Gzzj,Fxbg,Hdfa,Pxzj,Jhxd,Zw,Xkct,Hdch,Ds,Yzhp,Wz,Yjzx,Swot,Aibc,Zbgs,Lw,Sgqs,Xxjh,
        Tkx,Jrzf,Hny,Kzs,Sgzs,Lxgl,Gxqm,Xqrz,Rap

    },
    props: {
        lable: {
            type: Object,
            default: {}
        }
    },
    created() {
        this.getPrompt(this.lable.type)
        this.existencepage(this.lable.type)
        this.degree()
    },
    mounted() {
        EventBus.$on('anwserOver', () => {
            setTimeout(() => {
                this.disabled = false
                this.btnText = "立即生成";
            }, 100)

        });
    },
    beforeDestroy() {
        EventBus.$off('anwserOver'); // 确保移除事件监听器以避免内存泄漏
    },
    data() {
        return {
            content: {},// 展示内容
            pageShow: false, //请求完成
            btnText: '立即生成',//btn按钮
            disabled: false, //是否禁用
            login: false, //判断是否登录
            freenum: -2, //免费次数
            typeList: pagelist,  //所有存在页面
            found: false,  //是否存在
            
        }
    },
    methods: {
        //判断是否存在
        existencepage(type) {
            for (const element of this.typeList) {
                if (element == type) {
                    this.found = true;
                    break;
                }
            }
        },
        // 获取提示信息
        getPrompt(i) {
            this.found = false
            this.btnText = '立即生成'
            this.disabled = false
            this.existencepage(i)
            if (i == 'zwpg') {
                this.btnText = '立即批改'
                return
            } else if (i == 'wdlj') {
                return
            }
            this.pageShow = false
            api.getPrompt(i).then((res) => {
                if (res.code == 200) {
                    let length = 0
                    if (res.data.text_lenth) {
                        length = res.data.text_lenth
                        res.data.text_lenth = '请输入字数(<=' + res.data.text_lenth + ')'
                    }
                    this.content = res.data;
                    this.content.length = length
                    this.pageShow = true;
                }
                console.log(this.content)
            });
        },
        //立即创作
        generate() {
            if (!this.login) {
                this.$message({
                    message: '请先登录',
                    type: "warning",
                });
                this.$emit('login')
                return
            }
            if (this.freenum == 0) {
                this.$message({
                    message: '免费次数已用尽！',
                    type: "warning",
                });
                this.$router.push({ path: '/vip' });
                return
            }

            if (this.btnText == "创作中") {
                this.$message({
                    message: '请耐心等待创作完成！',
                    type: "warning",
                });
                return
            }

            if (this.lable.name == '作文批改') {
                if (this.$refs.creation.upImage()) {
                    this.$refs.creation.upImage()
                }
                return
            }
            if (this.lable.name == '文档理解') {
                this.btnText = "创作中";
                this.disabled = true;
                if (this.$refs.creation.upIxt()) {
                    this.$refs.creation.upIxt()
                }
                return
            }

            if (this.$refs.creation.getInfo()) {
                this.btnText = "创作中";
                this.disabled = true;
                this.$emit("getInfo", this.$refs.creation.getInfo());
                this.degree()
            }
        },
        // 获取免费次数
        degree() {
            api.degree().then((res) => {
                if (res == 401) {
                    this.login = false
                } else if (res.code == 200) {
                    this.login = true
                    this.freenum = res.data
                }
            })
        }
    }
}

</script>

<style>
.createContent {
    width: 28rem;
    height: 90vh;
    background-color: #FFFFFF;
    /* background-color: orange; */
    padding: 1.5vh 0;
    border-radius: 0.5rem 0 0 0.5rem;
    position: relative;
}

.createContent1 {
    height: 4vh;
    line-height: 4vh;
    font-size: 1.1rem;
    color: #111111;
    font-weight: 600;
    border-radius: 0.5rem;
    cursor: default;
    margin-bottom: 1.5vh;
    padding-left: 2rem;
    padding-top: 2px;
    display: flex;
    align-items: center;
}

.createContent1 img {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1.2rem;
}

.createContent3 {
    position: absolute;
    bottom: 1vh;
    left: 50%;
    transform: translate(-50%);
    cursor: default;
}

.createContent4 {
    width: 7rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 0.5rem;
    border-radius: 0.8rem 0.8rem 0.8rem 0;
    color: #FFFFFF;
    background-color: #409EFF;
}

.createContent5 {
    width: 20rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    color: #FFFFFF;
    border-radius: 3rem;
    background: linear-gradient(144deg, #409EFF 0%, #8956FF 100%);
}

.createContent6 {
    width: 15rem;
    margin: 0 auto;
    margin-top: 10rem;
}

.createContent6 img {
    width: 100%;
}

.createContent7 {
    width: 100%;
    height: 76vh;
    /* background-color: #409EFF; */
    overflow: auto;
    -ms-overflow-style: none;
    /* IE和Edge浏览器下隐藏滚动条 */
    scrollbar-width: none;
    /* Firefox下隐藏滚动条 */
}
</style>