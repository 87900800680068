<template>
  <div id="talk">
    <top index="1" @login="login"></top>
    <div class="talk_content">
      <talkhistory @hisDetail="hisDetail" @newdialogue="newTalk"></talkhistory>
      <div class="talk_dialogue">
        <!-- 提示问题 -->
        <tips v-if="tipsShow" @putQuestion="askQuestion"></tips>
        <!-- 对话 -->
        <div v-else class="talk_dialogue1" ref="chatContainer">
          <dialogue ref="dialogue" :head="headicon" :max="88" @anwserOver="anwserOver" @downloadW="downloadW"
            @downloadE="downloadE"></dialogue>
        </div>
        <!-- 键盘 -->
        <div class="keyboard_bottom1">
          <keyboard ref="keyboard" @login="login" @putQuestion="askQuestion" @getThink="getThink" @getSearch="getSearch"
            :nextQuestion="nextanwser" @stopTalk="stopTalk">
          </keyboard>
          <div class="bottom_number">
            <a href="https://beian.miit.gov.cn"> 鄂ICP备2023031487号 </a>
            <div>Copyright © 2023 - 2025 hbsry.com 版权所有</div>
          </div>
        </div>
      </div>
    </div>
    <login ref="login"></login>
    <gzhpopup></gzhpopup>
  </div>
</template>

<script>
import top from '@/components/top.vue'
import talkhistory from '@/components/talk_history.vue'
import dialogue from '@/components/dialogue.vue';
import tips from '@/components/tips.vue'
import keyboard from '@/components/keyboard.vue'
import login from '@/components/login.vue';
import api from "@/api/api";
import gzhpopup from '@/components/gzhpopup.vue'
import * as XLSX from 'xlsx';
import { exportWord } from 'mhtml-to-word';
import { marked } from 'marked';

export default {
  metaInfo() {
    return {
      title: 'AI对话',
      meta: [
        {name:'description',content:'智能对话'},
        {name:'keywords',content:'AI,智能,对话'},
      ]
    }
  },
  components: {
    top, talkhistory, dialogue, tips, keyboard, login, gzhpopup
  },
  data() {
    return {
      tipsShow: true, //提示内容显示
      talkList: [], //ai对话内容
      sessionId: 0, //会话id
      headicon: require('@/assets/image/Aitalk.png'), //头像
      nextanwser: false, //下一个问题
      stopTalkid: 0, // 停止对话id
      intervalId: null, // 定时器ID
      ifTable: false,
      talkEnd: false, //流式结束
      ifthink: false, //是否深度思考
      thinkText: '', //储存思考原文
      anwserText: '', //储存回答原文
      ifsearch: false, //是否联网搜索
      inthink: false, //返回结果是否联网
      streamIndex: 0, //流式返回下标
    }
  },
  created() {
    this.newTalk();
  },
  methods: {
    // 登录
    login() {
      this.$refs.login.show()
    },
    // 创建新对话
    newTalk(i) {
      if (i == "new") {
        this.tipsShow = true
        this.talkList = [];
        if (!this.tipsShow) {
          setTimeout(() => {
            this.$refs.dialogue.getQuestion(this.talkList);
          }, 10)
        }
      }
      api.createSession().then((res) => {
        if (res.code == 200) {
          if (i == "new") {
            this.$message({
              message: "新对话已创建",
              type: "success",
            });
          }
          this.sessionId = res.data;
        }
      });
    },
    // 查询对话详情
    hisDetail(data) {
      this.tipsShow = false
      this.pageNum = 1;
      this.talkList = [];
      this.sessionId = data.id
      this.headicon = data.icon
      api.contentSession({
        sessionId: data.id,
        pageNum: this.pageNum,
        pageSize: 50,
      }).then((res) => {
        if (res.code == 200) {
          for (let item of res.data) {
            item.downloadShow = false
          }
          this.talkList = this.talkList.concat(res.data.reverse());
        }
        setTimeout(() => {
          this.$refs.dialogue.getQuestion(this.talkList);
          this.scrollToBottom();
        }, 10)
      })

    },
    // 获取是否深度思考
    getThink(item) {
      this.ifthink = item
      this.inthink = item
    },
    //获取是否联网搜索
    getSearch(item) {
      this.ifsearch = item
    },

    // 提问
    askQuestion(item) {
      if (this.sessionId == 0) {
        this.$refs.login.show()
        return
      }
      this.talkEnd = false
      this.tipsShow = false
      this.nextanwser = true;
      let arry = {
        question: item,
        sessionId: this.sessionId,
        think: this.ifthink,
        search: this.ifsearch,
        anwser: "",
        downloadShow: false,
      };
      this.talkList.push({
        question: item,
        sessionId: this.sessionId,
        think: '',
        anwser: "",
        downloadShow: false,
      });
      this.getAnwser(arry);
      setTimeout(() => {
        this.$refs.dialogue.getQuestion(this.talkList);
      }, 10)
      this.intervalId = setInterval(() => {
        this.scrollToBottom();
      }, 500)

    },
    // 流式请求获取回答
    async getAnwser(data) {
      api.getStreamId(data).then((res) => {
        if (res.code == 200) {
          this.stopTalkid = res.data
          this.getStreamContent(res.data)
        }
      })
    },
    getStreamContent(id) {
      api.getStreamContent(id, { index: this.streamIndex }).then((res) => {
        if (res.data.length !== 0) {
          this.streamIndex = this.streamIndex + res.data.length
          res.data.map((item) => {
            if (item == '</think>') {
              this.inthink = false
            }
            if (item !== '[DONE]') {
              if (this.inthink) {
                this.thinkText += item
                this.talkList[this.talkList.length - 1].think = marked(this.thinkText)
              } else {
                this.anwserText += item
                this.talkList[this.talkList.length - 1].anwser = marked(this.anwserText)
                if (this.talkList[this.talkList.length - 1].anwser.indexOf("<table>") !== -1) {
                  this.talkList[this.talkList.length - 1].downloadShow = true
                }
              }
            }
          })
          if (res.data[res.data.length - 1] == '[DONE]') {
            this.streamIndex = 0
            this.thinkText = ''
            this.anwserText = ''
            this.inthink = this.ifthink
            this.$refs.dialogue.over()
            return
          }
          this.getStreamContent(id)
        } else {
          this.getStreamContent(id)
        }
      })
    },

    scrollToBottom() {
      // 使用Vue的$nextTick确保DOM更新完成后再进行滚动操作
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;
        chatContainer.scrollTop = chatContainer.scrollHeight; // 滚动到底部
      });
    },
    anwserOver() {
      // 问题回答完毕
      this.nextanwser = false;
      clearInterval(this.intervalId)
    },
    // 下载
    downloadW(item) {
      if (item.i == this.talkList.length - 1) {
        if (this.nextanwser) {
          this.$message({
            message: '请等待问题回答完毕，或者手动停止！',
            type: 'warning'
          });
          return
        }
        this.downloadWord(item.html)
      } else {
        this.downloadWord(item.html)
      }
    },
    downloadWord(html) {
      let htmlStr = `
                      <!DOCTYPE html>
                      <html lang="en">
                      <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Document</title>
                      </head>
                      <body>
                        ${html}
                      </body>
                      </html>
                    `;
      exportWord({
        mhtml: htmlStr,
        data: { title: "exportword" },
        filename: new Date().getTime(),
        style: ""
      })
    },
    downloadE(item) {
      if (item.i == this.talkList.length - 1) {
        if (this.nextanwser) {
          this.$message({
            message: '请等待问题回答完毕，或者手动停止！',
            type: 'warning'
          });
          return
        }
        this.downloadExcel(item.html)
      } else {
        this.downloadExcel(item.html)
      }

    },
    downloadExcel(item) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(item, 'text/html');
      const tables = doc.querySelectorAll('table');
      const workbook = XLSX.utils.book_new();

      // 遍历每个表格并添加到工作簿的不同工作表中
      tables.forEach((table, index) => {
        const worksheet = XLSX.utils.table_to_sheet(table);
        const sheetName = `Sheet${index + 1}`;
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      });

      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
      const fileName = new Date().getTime() + '.xlsx';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    },
    //停止对话
    stopTalk() {
      api.stopquestion(this.stopTalkid).then((res) => {
        if (res.code == 200) {
          clearInterval(this.intervalId)
        }
      })
    }
  }
}
</script>

<style>
#talk {
  width: 128rem;
  height: 100vh;
  background: url('../assets/image/bg.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 5rem;
}

.talk_content {
  width: 128rem;
  height: 90vh;
  /* background-color: aqua; */
  padding: 0;
  margin-top: 1vh;
  display: flex;

}

.talk_dialogue {
  width: 114rem;
  height: 90vh;
  position: relative;
}

.talk_dialogue1 {
  width: 70rem;
  height: 70vh;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE和Edge浏览器下隐藏滚动条 */
  scrollbar-width: none;
  /* Firefox下隐藏滚动条 */
  margin: 0 auto;
}

.keyboard_bottom1 {
  width: 70rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.bottom_number {
  width: 70rem;
  text-align: center;
  font-weight: 600;
  color: #000000;
  font-size: 0.8rem;
  margin-top: 1vh;
}

.table_style {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid #d0d7de;

}

th {
  background-color: #c4c4c4;
}

th,
td {
  padding: 10px;
  text-align: center;
}
</style>